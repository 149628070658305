<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Revisión Servicios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga Seca</li>
                  <li class="breadcrumb-item active">Internos</li>
                  <li class="breadcrumb-item active">Revisión Servicios</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-md-1">
                    <label>N° </label>
                    <input
                      type="number"
                      v-model="filtros.id"
                      placeholder="N°"
                      label="id"
                      class="form-control form-control-sm"
                      @keyup.enter="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Tipo servicio </label>
                    <select
                      id="tipo_servicio"
                      class="form-control form-control-sm"
                      v-model="filtros.tipo_servicio"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_servicio in listasForms.tipos_servicios"
                        :key="tipo_servicio.numeracion"
                        :value="tipo_servicio.numeracion"
                      >
                        {{ tipo_servicio.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Placa </label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.placa"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Conductor/Operador </label>
                    <select
                      id="persona_type"
                      v-model="filtros.persona_type"
                      class="form-control form-control-sm p-0"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option value="App\Conductor">Conductor</option>
                      <option value="App\Operador">Operador</option>
                    </select>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Nombre </label>
                    <input
                      type="text"
                      id="nombre"
                      class="form-control form-control-sm"
                      v-model="filtros.nombre"
                      @input="getIndex()"
                    />
                  </div>
                </div>
                <div class="row">
                  <!-- MultiSelect Empresa -->
                  <div class="form-group col-md-8">
                    <label>Empresa Transportadora </label>
                    <v-select
                      :class="[
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="empresa"
                      placeholder="Razon Social"
                      label="razon_social"
                      :options="listasForms.empresas"
                      multiple
                      @input="selectEmpresas()"
                      class="form-control form-control-sm p-0"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Bloque </label>
                    <v-select
                      :class="[
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="bloque"
                      placeholder="Nombre"
                      label="nombre"
                      :options="listasForms.bloques"
                      multiple
                      @input="selectBloque()"
                      :filterable="false"
                      class="form-control form-control-sm p-0"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Sitio </label>
                    <v-select
                      :class="[
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="sitio"
                      placeholder="Nombre"
                      label="nombre"
                      :options="listasForms.sitios"
                      multiple
                      @input="selectSitio()"
                      class="form-control form-control-sm p-0"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Fecha inicial </label>
                    <input
                      type="date"
                      id="fecha_ini"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_ini"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Fecha Final </label>
                    <input
                      type="date"
                      id="fecha_fin"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_fin"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>Estado </label>
                    <v-select
                      :class="[
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="estados"
                      placeholder="Estado"
                      label="descripcion"
                      :options="listasForms.estados"
                      multiple
                      @input="selectEstados()"
                      class="form-control form-control-sm p-0"
                    ></v-select>
                  </div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn bg-navy mt-4"
                        @click="getIndex()"
                      >
                        <i class="fas fa-search"></i><br />Buscar
                      </button>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-danger mt-4"
                        @click="limpiar()"
                      >
                        <i class="fas fa-broom"></i><br />Limpiar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- pre>{{ serviciosInternos.data }}</pre> -->
              <div class="card-body table-responsive p-0">
                <table
                  class="table table-bordered table-striped table-hover table-sm text-nowrap mb-0"
                  style="font-size: 0.85em"
                >
                  <thead>
                    <tr>
                      <th class="col-1">#</th>
                      <th>Tipo Servicio</th>
                      <th>Placa</th>
                      <th>Conductor/Operador</th>
                      <th>Nombre</th>
                      <th>Fecha Inicial</th>
                      <th>Fecha Final</th>
                      <th>Bloque</th>
                      <th>Sitio</th>
                      <th>Empresa Transportadora</th>
                      <th>Estado</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="servicio in serviciosInternos.data"
                      :key="servicio.id"
                    >
                      <td class="text-center">{{ servicio.id }}</td>
                      <td>{{ servicio.nTipoServicio }}</td>
                      <td class="text-center">
                        {{
                          servicio.equipo !== null &&
                          servicio.equipo !== undefined
                            ? servicio.equipo.placa
                            : ""
                        }}
                      </td>
                      <td>
                        {{
                          servicio.persona_type == "App\\Conductor"
                            ? "Conductor"
                            : "Operario"
                        }}
                      </td>
                      <td>
                        <div
                          v-if="
                            servicio.persona !== null &&
                              servicio.persona !== undefined
                          "
                        >
                          {{ servicio.persona.nombres }}
                          {{ servicio.persona.apellidos }}
                          <span class="badge bg-lightblue float-right">
                            {{ servicio.persona.numero_documento }}
                          </span>
                        </div>
                      </td>
                      <td class="text-center">{{ servicio.fecha_ini }}</td>
                      <td class="text-center">{{ servicio.fecha_fin }}</td>
                      <td>
                        {{
                          servicio.bloque !== null &&
                          servicio.bloque !== undefined
                            ? servicio.bloque.nombre
                            : ""
                        }}
                      </td>
                      <td>
                        {{
                          servicio.sitio !== null &&
                          servicio.sitio !== undefined
                            ? servicio.sitio.nombre
                            : ""
                        }}
                      </td>
                      <td>
                        {{
                          servicio.empresa.razon_social !== null &&
                          servicio.empresa.razon_social !== undefined
                            ? servicio.empresa.razon_social
                            : ""
                        }}
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            servicio.estado == 1
                              ? 'badge-secondary'
                              : servicio.estado == 2
                              ? 'badge-warning'
                              : servicio.estado == 3
                              ? 'bg-navy'
                              : servicio.estado == 4
                              ? 'bg-orange'
                              : servicio.estado == 5
                              ? 'badge-info'
                              : servicio.estado == 6
                              ? 'badge-success'
                              : 'bg-light'
                          "
                        >
                          {{ servicio.nEstado }}
                        </span>
                        <i
                          :class="
                            servicio.tarifas.length > 0
                              ? 'fas fa-check'
                              : 'fas fa-times'
                          "
                        ></i>
                      </td>
                      <td class="text-right">
                        <div class="btn-group">
                          <button
                            type="button"
                            class="btn btn-sm bg-info"
                            data-toggle="modal"
                            data-target="#modal-form-detalles"
                            @click="$refs.CsRevisionDetalles.getIndex(servicio)"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="serviciosInternos.total">
                  <p>
                    Mostrando del <b>{{ serviciosInternos.from }}</b> al
                    <b>{{ serviciosInternos.to }}</b> de un total:
                    <b>{{ serviciosInternos.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="serviciosInternos"
                  @pagination-change-page="getIndex"
                  :limit="10"
                  class="float-right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <CsRevisionDetalles ref="CsRevisionDetalles"></CsRevisionDetalles>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import CsRevisionDetalles from "./CsRevisionDetalles";
import vSelect from "vue-select";

export default {
  name: "CsRevisionIndex",
  components: {
    Loading,
    vSelect,
    pagination,
    CsRevisionDetalles,
  },

  data() {
    return {
      cargando: false,
      sitio: null,
      bloque: null,
      empresa: null,
      estados: null,
      serviciosInternos: {},
      listasForms: {
        tipos_servicios: [],
        empresas: [],
        estados: [],
      },
      filtros: {},
      estados_modulo: [4, 5, 6],
      clasesTarifas: null,
      tiposTiemposTarifas: null,
      page: 1,
    };
  },

  methods: {
    async getIndex(page = 1) {
      this.cargando = true;
      const me = this;
      this.filtros.in_estado = this.estados_modulo;
      this.page = page;

      await axios
        .get("/api/cs/revision_servicios_internos?page=" + this.page, {
          params: this.filtros,
        })
        .then(async (response) => {
          this.serviciosInternos = response.data;
          this.cargando = false;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error :" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getTipoServicio() {
      axios.get("/api/lista/128").then((response) => {
        this.listasForms.tipos_servicios = response.data;
      });
    },

    getEstados() {
      axios
        .get("/api/lista/143", {
          params: { in_number: this.estados_modulo },
        })
        .then((response) => {
          this.listasForms.estados = response.data;
        });
    },

    selectEstados() {
      this.filtros.estados = [];
      if (this.estados) {
        this.filtros.estados = this.estados.map((e) => e.numeracion);
      }
      this.getIndex();
    },

    getEmpresa() {
      axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    selectEmpresas() {
      this.filtros.empresas = [];
      if (this.empresa) {
        this.filtros.empresas = this.empresa.map((e) => e.id);
      }
      this.getIndex();
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    selectBloque() {
      this.filtros.sitios = [];
      this.filtros.bloques = [];
      if (this.bloque) {
        this.filtros.bloques = this.bloque.map((e) => e.id);
      }
      this.getIndex();
    },

    getSitios() {
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
      });
    },

    selectSitio() {
      this.filtros.sitios = [];
      if (this.sitio) {
        this.filtros.sitios = this.sitio.map((e) => e.id);
      }
      this.getIndex();
    },

    // Listas para las tarifas trabajos
    getTipoTiempoTarifa() {
      axios.get("/api/lista/165").then((response) => {
        this.tiposTiemposTarifas = response.data;
      });
    },

    getClaseTarifa() {
      axios.get("/api/lista/166").then((response) => {
        this.clasesTarifas = response.data;
      });
    },

    limpiar() {
      this.filtros.id = "";
      this.filtros.tipo_servicio = "";
      this.filtros.placa = "";
      this.filtros.persona_type = "";
      this.filtros.nombre = "";
      this.empresa = null;
      this.filtros.empresas = null;
      this.filtros.bloques = null;
      this.bloque = null;
      this.filtros.bloque = null;
      this.filtros.sitio_id = null;
      this.sitio = null;
      this.filtros.sitio = null;
      this.filtros.fecha_ini = "";
      this.filtros.fecha_fin = "";
      this.estados = null;
      this.filtros.estados = "";
      this.getIndex();
    },
  },

  beforeMount() {
    this.getTipoServicio();
    this.getTipoTiempoTarifa();
    this.getClaseTarifa();
    this.getEmpresa();
    this.getBloques();
    this.getSitios();
    this.getEstados();
  },

  mounted() {
    this.getIndex();
  },
};
</script>
<style>
.v-select,
.v-select * {
  height: auto;
}
.vs__selected {
  white-space: wrap;
}
</style>
